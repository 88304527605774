import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
Vue.config.productionTip = false

import "@/assets/css/styles.css";
import '@/assets/fonts/icomoon/style.css';
import "@/assets/fonts/flaticon/font/flaticon.css";
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'


Vue.use(VModal)
Vue.use(VueMeta);

Vue.use(BootstrapVue);

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-779XQ73RDT" }
});


new Vue({
  render: h => h(App),
}).$mount('#app')
