<template>
    <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <div class="lead mb-3">Keywords: {{keywords}}</div>
                <p>{{abstract}}</p>
                <p>Download pdf:
                <a :href="linkpdf" download>
                        <i class="icon-file-pdf-o" style="font-size: 22px"></i>
                </a>
                </p>
                <!-- <p>
                    Watch the video presentation:
                    <button class="btn-inv" v-on:click="videoWatch=!videoWatch"><i class="icon-file-video-o" style=" font-size: 22px"></i></button>
                </p>
                <figure v-if="videoWatch" style="width: 100%">
                      <p><video width="650" controls :src="require(`../assets/video/${linkvideo}.mp4`)"></video>
                      <figcaption></figcaption>
                </figure> -->

            </div>
        </div>
</template>

<script>
    export default {
  name: 'paper',
  props:{
    linkpdf: String,
    linkvideo: String,
    abstract: String,
    keywords: String
  },
  data: () => ({ videoWatch: false })

}
</script>
