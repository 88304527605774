<template>
  <section class="resume-section" id="about" style="margin-top: -7rem">
                <div class="resume-section-content">
                    <h2 class="mb-2">Maria
                        <span class="text-primary">Kabtoul</span>
                    </h2>
                    <div class="lead mb-5">
                      <div class="row">
                      <div class="col-lg-8">
                        <p>Founder @<a href="https://automatikarobotics.com/" target="_blank">Automatika</a>, PhD</p>

                      </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 social-icons">
                        <a class="social-icon" href="https://www.linkedin.com/in/maria-kabtoul-73075aa0"><i class="fab icon-linkedin"></i></a>
                        <a class="social-icon" href="https://www.researchgate.net/profile/Maria_Kabtoul2"><img width="50%" style="margin: 0; padding: 0" src="../assets/img/RG_icon.png"></a>
                        <a class="social-icon" href="mailto: maria@automatikarobotics.com"><i class="fab icon-envelope-o"></i></a>
                    </div>
                      </div>
                    </div>


                    <p class="lead mb-4">I am the founder and CEO of <a href="https://automatikarobotics.com/">Automatika Robotics</a>, a deeptech startup offering the software infrastructure for general purpose robots to intelligently comprehend space and human behavior. Automatika is a spin-off from the premier french institute for research in computer science <a href="https://www.inria.fr/fr">Inria</a>.
                    </p>
                    <p>
                      I received my PhD degree in robotics and applied mathematics from <a href="https://www.inria.fr/fr">Inria</a> and <a href="https://www.univ-grenoble-alpes.fr/" target="_blank">Université Grenoble Alpes</a> in late 2021. My PhD research was focused on proactive and socially-aware navigation for autonomous vehicles in European city-centers. 
                     <!--  My thesis was part of the <a href="https://project.inria.fr/hianic/">HIANIC</a> project, and it is was under the supervision of <a href="https://sites.google.com/view/annespalanzani/home?authuser=0">Anne Spalanzani</a> and <a href="http://www-sop.inria.fr/members/Philippe.Martinet/">Philippe Martinet</a>. -->
                      <br>
                        I previously received my MSC degree in <a href="http://www.gipsa-lab.grenoble-inp.fr/MiSCIT/home/" target="_blank">Control Theory</a> from <a href="https://www.grenoble-inp.fr/" target="_blank">Grenoble INP</a> in 2018.
                        I hold a bachelor’s degree with honors in Electronic Systems Engineering from the <a href="https://hiast.edu.sy/en">Higher Institute for Applied Sciences and Technology</a> in my hometown <a href="https://www.youtube.com/watch?v=wyLLDRRzz98" target="_blank">Damascus</a> (<a href="https://www.syriamusicmap.org/en/home" target="_blank">Syria</a>).
                      </p>

<!-- 
                      <p class="lead mb-3">
                        I have worked with many technologies, but these days I am primarily working with:
                      </p>
                        <ul class="list-inline dev-icons row mb-3">
                          <li class="list-inline-item col-lg-2"><a href="https://www.ros.org/"><img class="scaled" src="../assets/img/ros2-logo.png"
                                alt="ROS"></a></li>
                          <li class="list-inline-item col-lg-2"><a href="https://www.python.org/"><img class="scaled" src="../assets/img/python-logo.png"
                          alt="Python"></a></li>
                          <li class="list-inline-item col-lg-2"><a href="https://www.cplusplus.com/"><img class="scaled" src="../assets/img/cpp_logo.png"
                          alt="C++"></a></li>
                          <li class="list-inline-item col-lg-2"><a href="https://www.tensorflow.org/?hl=fr"><img class="scaledd" src="../assets/img/TensorFlow_logo.png"
                          alt="TenserFlow"></a></li>
                          <li class="list-inline-item col-lg-2"><a href="https://carla.org/"><img class="scaled" src="../assets/img/carla_logo.png"
                          alt="Carla"></a></li>
                      </ul>
                   -->
                </div>
            </section>
</template>

<script>

export default {
  name: 'Intro',
  components: {
  }

}
</script>

<style>
figure {
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  margin: 0.1em;
  padding: 0.1em;
}
img.scaled {
  max-width: 70%;
  height: auto;
}
img.scaledd {
  max-width: 90%;
  height: auto;
}
.img-hover:hover{
    transform: scale(1.5)
}
.text-fade{
  color: gray;
  font-size: 18px
}
</style>

