<template>
<section class="resume-section" id="awards">
    <div class="resume-section-content">
        <h3 class="mb-5">Awards & Honors</h3>
        <ul class="mb-0" style="list-style-type: none; margin-left: -2rem;">
            <li>
                <span class="fa-li icon-trophy text-warning" style="font-size: 20px; margin-right: 5px"></span>
                Awarded the <a href="https://persyval-lab.org/">PERSYVAL-Lab</a> Excellence Scholarship for the year 2017-2018.
            </li>
            <li>
                <span class="fa-li icon-trophy text-warning" style="font-size: 20px; margin-right: 5px"></span>
                Ranked 1
                <sup>st</sup>
                , Class of 2018, CST specialization, Master in Systems Control and IT, <a href="https://www.univ-grenoble-alpes.fr/">UGA</a>
            </li>
            <li>
                <span class="fa-li icon-trophy text-warning" style="font-size: 20px; margin-right: 5px"></span>
                Ranked 1
                <sup>st</sup>
                , Class of 2016, Electronic Systems Engineering, <a href="https://hiast.edu.sy/en">HIAST</a>
            </li>
        </ul>
    </div>
</section>
</template>

<script>
export default {
  name: 'Awards',

}
</script>

