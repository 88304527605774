<template>
<section class="resume-section" id="projects">
    <div class="resume-section-content">
        <h3 class="mb-5">Projects</h3>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h4 class="mb-3"><a style="color: #212529;" href="https://project.inria.fr/hianic/">HIANIC</a> (<span class="text-primary">H</span><span class="text-fade">uman</span> <span class="text-primary">I</span><span class="text-fade">nspired</span> <span class="text-primary">A</span><span class="text-fade">utonomous</span> <span class="text-primary">N</span><span class="text-fade">avigation</span> <span class="text-primary">I</span><span class="text-fade">n</span> <span class="text-primary">C</span><span class="text-fade">rowds</span>)</h4>

                <p>I have been a member of the HIANIC project from the start of my PhD.<br> The main challenge of this project is autonomous vehicle navigation in shared spaces:</p>
                <figure style="width: 100%" class="mb-3">
                  <p><img class="scaled" src="../assets/img/work/shared_spaces.png"
                    alt="shared_spaces">
                  <figcaption>The shared spaces scenarios targeted in the <a href="https://project.inria.fr/hianic/">HIANIC</a> project</figcaption>
                </figure>
                <p>The project is funded by The Agence nationale de la recherche <a href="https://anr.fr/">ANR</a>, and is made of 4 main partners with 6 research teams</p>
                <figure style="width: 100%;" class="mb-3">
                  <p><img style="width: 70%" src="../assets/img/work/hianic_scoop.png"
                    alt="shared_spaces">
                  <figcaption>My work is focused on the navigation system of the vehicle which takes into account: PVI (pedestrian-vehicle interaction), Pessenger's comfort and Vehicle's Legibility.</figcaption>
                </figure>
            </div>
            <!-- <div class="flex-shrink-0"><span class="text-primary">Sep. 2018 - Present</span></div> -->
        </div>
    </div>
</section>
</template>


<script>
export default {
  name: 'Projects',

}
</script>

<style>
figure {
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  margin: 0.1em;
  padding: 0.1em;
}
img.scaled {
  width: 100%;
}
.img-hover:hover{
    transform: scale(1.5)
}
.text-fade{
  color: gray;
  font-size: 18px
}
</style>

