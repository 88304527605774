<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a class="navbar-brand js-scroll-trigger" href="#about">
                <span class="d-block d-lg-none">Maria Kabtoul</span>
                <span class="d-none d-lg-block">
                  <!-- <expandable-image
                  class="img-fluid img-profile rounded-circle mx-auto mb-2"
                  :src="require('@/assets/img/profile0.jpg')"
                  alt="profile"
                  :close-on-background-click="true">
                </expandable-image> -->
    <div @click="$modal.show('example')" style="cursor:pointer;">
    <img class="img-fluid img-profile rounded-circle mx-auto mb-2" src="@/assets/img/profile2.jpg" alt=""/>
  </div>
  </span>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About me</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#experience">My Research</a></li>
                    <!-- <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#skills">Key Skills</a></li> -->
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#projects">Projects</a></li>
                    <!-- <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#education">Education</a></li> -->
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#publications">Publications</a></li>
                    <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#awards">Awards</a></li>
                </ul>
            </div>
        </nav>
    <div class="container-fluid p-0">
      <!-- <modal name="example" transition="pop-out" :width="modalWidth" :focus-trap="true" :height="420">
          <img src="@/assets/img/profile1.jpeg" />
      </modal> -->
    <IntroSection/>
    <hr class="m-0" />
    <WorkSection/>
    <hr class="m-0" />
    <!-- <SkillsSection/> -->
    <!-- <hr class="m-0" /> -->
    <ProjectsSection/>
    <hr class="m-0" />
    <!-- <EducationSection/> -->
    <!-- <hr class="m-0" /> -->
    <PublicationsSection/>
    <hr class="m-0" />
    <AwardsSection/>
    <hr class="m-0" />

  </div>


  </div>
</template>

<script>
const MODAL_WIDTH = 400
import IntroSection from './components/Intro.vue'
import WorkSection from './components/Work.vue'
// import EducationSection from './components/Education.vue'
import AwardsSection from './components/Awards.vue'
import PublicationsSection from './components/Publications.vue'
import ProjectsSection from './components/Projects.vue'

export default {
  name: 'App',
  components: {
    IntroSection,
    WorkSection,
    // EducationSection,
    AwardsSection,
    PublicationsSection,
    ProjectsSection,
    // SkillsSection
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Maria Kabtoul',
      // all titles will be injected into this template
      titleTemplate: '%s | PhD Researcher'
    },
  data:() => ({
    src: "@/assets/img/profile0.jpg",
    modalWidth: MODAL_WIDTH
  }),
  method:{
  },
  created() {
    this.modalWidth =
      window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
