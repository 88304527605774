<template>
<section class="resume-section" id="experience">
    <div class="resume-section-content">
        <h3 class="mb-5">Research Work</h3>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h4 class="mb-0">Autonomous Navigation In Shared Spaces</h4>
                <div class="lead mb-3"><a href="https://project.inria.fr/chorale/">CHORALE</a>\<a href="https://team.inria.fr/chroma/en/">CHROMA</a>, <a href="https://www.inria.fr/en/centre-inria-sophia-antipolis-mediterranee">INRIA</a></div>
                <p>My PhD research targets the challenge of autonomous vehicles navigation in close interaction with vulnerable road users.
                I am developing a navigation technique that exploits the cooperation of the surrounding agents in the space and grants the vehicle a sense of proactivity, while satisfying the strict safety constraints around the pedestrians.</p>
                <br>
                <p style="font-size: 22px">A sneak peek of my simulations & results:</p>
                <modal name="show-large" transition="pop-out" width="620" :focus-trap="true" :height="300">
                    <img src="../assets/video/lateral.gif" />
                </modal>
                <modal name="showFrontal" transition="pop-out" :width="600" :focus-trap="true" :height="220">
                    <img src="../assets/video/frontal_test_pedsim.gif" />
                </modal>
                <div class="row mb-5">
                    <div class="col-md-6">
                        <figure style="width: 100%">
                  <p><video width="400" controls src="../assets/video/Lat_coop_tagged.mp4"></video>
                  <figcaption>The output of the cooperative behavioral model for pedestrians in interaction with a vehicle <a href="https://ieeexplore.ieee.org/document/9196669">ICRA'20</a>. <br>The video recording is part of the <a href="https://github.com/dongfang-steven-yang/vci-dataset-citr">CITR</a> dataset.</figcaption>
                </figure>
                    </div>
                    <div class="col-md-6">
                        <figure style="width: 100%">
                  <p><img class="scaled" src="../assets/img/work/global_view.jpg"
                    alt="global_view">
                  <figcaption>Proactive navigation <br> and the cooperative behavioral model for pedestrians <a href="https://ieeexplore.ieee.org/document/9196669">ICRA'20</a></figcaption>
                </figure>
                    </div>
                </div>

                <figure style="width: 100%" class="mb-5">
                  <p><img class="scaled" src="../assets/video/gazebo_sims.gif"
                    alt="gazebo_sims">
                  <figcaption>A couple of the resulting <a href="http://gazebosim.org/">GAZEBO</a> <a href="https://www.ros.org/">ROS</a> simulations using the cooperative behavioral model, <a href="https://ieeexplore.ieee.org/document/9196669">ICRA'20</a></figcaption>
                </figure>
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <figure style="width: 80%">
                      <p>
                        <div @click="$modal.show('show-large')" style="cursor:pointer;">
                        <img class="scaled" src="../assets/video/lateral.gif"
                        alt="pedsim_challenge">
                      </div>
                      <figcaption>The challenging pedestrians-vehicle interactions targted in the work, <a href="https://hal.inria.fr/hal-02944369">ITSC'20</a>. Simulations using <a href="https://github.com/srl-freiburg/pedsim_ros">PedSim</a> <a href="https://www.ros.org/">ROS</a></figcaption>
                    </figure>
                    </div>
                 <!--    <div class="col-md-6">
                        <figure style="width: 100%">
                      <p>
                        <div @click="$modal.show('showFrontal')" style="cursor:pointer;">
                        <img class="scaled" src="../assets/video/frontal_test_pedsim.gif"
                        alt="pedsim_frontal">
                      </div>
                      <figcaption>My latest proactive navigation results on <a href="https://github.com/srl-freiburg/pedsim_ros">PedSim</a> under <a href="https://www.ros.org/">ROS</a></figcaption>
                    </figure>
                    </div> -->
                </div>
            </div>
            <!-- <div class="flex-shrink-0"><span class="text-primary">Sep. 2018 - Present</span></div> -->
        </div>

        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h4 class="mb-0">Efficient Diffusivity Inversion and Multiple Time Scales in Polar Firn Models</h4>
                <div class="lead mb-3"><a href="http://www.gipsa-lab.grenoble-inp.fr/en/sysco/research-areas/infinite-dimensionalhybrid-systems.php">Infinite Dimensional Dynamics</a> Team, <a href="http://www.gipsa-lab.grenoble-inp.fr/en">GIPSA-LAB</a></div>
                <p>In my master thesis I worked with <a href="http://www.gipsa-lab.grenoble-inp.fr/page_pro.php?vid=485">Emmanuel Witrant</a> from <a href="http://www.gipsa-lab.grenoble-inp.fr/en">GIPSA</a> Lab, and <a href="https://scholar.google.com/citations?user=f7xBio8AAAAJ&hl=fr">Patricia Martinerie</a> from <a href="http://www.ige-grenoble.fr/">IGE</a> Glaciologie Lab. <br>
                    I worked on estimating a scaling law for the diffusivity in polar firn using a multi-gas, multi-site approach. As well as, developing a faster inversion method based on a <a href="https://en.wikipedia.org/wiki/Linear_parameter-varying_control">LPV</a> model.</p>
                <div class="row">

                    <div class="col-lg-6">
                        <p style="float: right;">
                    Don't know what firn is? Well neither did I!! :)<br>
                        Firn is the partially compacted granular snow that is the intermediate stage between snow and glacial ice. Gases in the atmosphere diffuse slowly in the polar ice sheet layer through the
                        open paths connected to the surface. At the firn layer, the gases get trapped in tiny bubbles which are not connected to the surface anymore. As a result, this firn layer remains an archive of the atmospheric gases
                        corresponding to preceding times. Brave and cold defying people (like <a href="https://scholar.google.com/citations?user=f7xBio8AAAAJ&hl=fr">Patricia</a>) go on campains to extract firn samples to try and reconstruct our atmospheric history.
                    </p>
                    </div>
                    <div class="col-lg-6">
                        <a href="https://www.polartrec.com/expeditions/ocean-atmosphere-sea-ice-and-snowpack-interactions/journals/2013-09-05">
                        <img class="scaledd" src="../assets/img/work/firn.jpg"
                        alt="firn">
                    </a>
                    </div>

                </div>
            </div>
        </div>

                <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <h4 class="mb-0">Active Damping Of A Single Link Flexible Manipulator</h4>
                <div class="lead mb-3"><a href="https://hiast.edu.sy/en">HIAST</a></div>
                <p>In my Bachelor's thesis I worked on active damping to control the tip position of a single-link flexible manipulator supplied with two strain gauges in order to mesure the deflection. I explored the finite elements method to model the system, as well as the state of the art control methods used in active damping (Passivity-Based Control, Integral Resonant Control, Fuzzy Logic Control and Fuzzy-Resonant Control)
                    <br>
                Here is a small demo of the results we got:</p>
                <figure style="width: 100%">
                      <p><video width="600" controls src="../assets/video/hiast_active_damping.mp4"></video>
                      <figcaption></figcaption>
                    </figure>
            </div>
        </div>

    </div>
</section>
</template>

<script src='https://unpkg.com/vue@2.2.6/dist/vue.js'></script>
<script src="../dist/vue-gif-player.min.js"></script>
<script>
const MODAL_WIDTH = 630
export default {
  name: 'Work',
  data:() => ({
    src: "",
    modalWidth: MODAL_WIDTH
  }),
  created() {
    this.modalWidth =
      window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH
  },

}
</script>

<style>
figure {
  text-align: center;
  font-style: italic;
  font-size: smaller;
  text-indent: 0;
  margin: 0.1em;
  padding: 0.1em;
}
img.scaled {
  width: 100%;
}
img.scaledd {
  width: 70%;
  height: 90%;
  float: left;
}
.img-hover:hover{
    transform: scale(1.5)
}
</style>

