<template>
<section class="resume-section" id="publications">
    <div class="resume-section-content">
        <h3 class="mb-5">Publications</h3>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <p class="lead mb-0" style="font-size: 23px"><a href="https://tel.archives-ouvertes.fr/I3S/hal-03596570v1">Proactive And Smooth Maneuvering For Navigation Around Pedestrians</a>, ICRA 2022.</p>
                <div class="lead mb-3">Maria Kabtoul, Anne Spalanzani and Philippe Martinet</div>
                <button v-if="!findMore0" class="btn-inv" v-on:click="findMore0=true">[more]</button>
                <Paper v-if="findMore0" :abstract="icra22Abstract" :keywords="icra22Keywords" :linkpdf="`${publicPath}ICRA22_Kabtoul_Spalanzani_Martinet.pdf`" :linkvideo="itscVideo"/>
                <button v-if="findMore0" class="btn-inv" v-on:click="findMore0=false">[less]</button>
            </div>
        </div>

        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <p class="lead mb-0" style="font-size: 23px"><a href="https://tel.archives-ouvertes.fr/I3S/hal-03596570v1">How To Evaluate the Navigation of Autonomous Vehicles Around Pedestrians?</a>, IEEE Transactions on Intelligent Transportation Systems (T-ITS).</p>
                <div class="lead mb-3">Maria Kabtoul, Manon Predhumeau, Anne Spalanzani, Julie Dugdale and Philippe Martinet</div>
                <button v-if="!findMore3" class="btn-inv" v-on:click="findMore3=true">[more]</button>
                <Paper v-if="findMore3" :abstract="titsAbstract" :keywords="titsKeywords"/>
                <button v-if="findMore3" class="btn-inv" v-on:click="findMore3=false">[less]</button>
            </div>
        </div>

        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <p class="lead mb-0" style="font-size: 23px"><a href="https://hal.inria.fr/hal-02944369">Proactive Longitudinal Velocity Control In Pedestrians-Vehicle Interaction Scenarios</a>, ITSC 2020.</p>
                <div class="lead mb-3">Maria Kabtoul, Philippe Martinet and Anne Spalanzani</div>
                <button v-if="!findMore1" class="btn-inv" v-on:click="findMore1=true">[more]</button>
                <Paper v-if="findMore1" :abstract="itscAbstract" :keywords="itscKeywords" :linkpdf="`${publicPath}ITSC20_Kabtoul_Martinet_Spalanzani.pdf`" :linkvideo="itscVideo"/>
                <button v-if="findMore1" class="btn-inv" v-on:click="findMore1=false">[less]</button>
            </div>
        </div>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <p class="lead mb-0" style="font-size: 23px"><a href="https://ieeexplore.ieee.org/document/9196669">Towards Proactive Navigation: A Pedestrian-Vehicle Cooperation Based Behavioral Model</a>, ICRA 2020.</p>
                <div class="lead mb-3">Maria Kabtoul, Anne Spalanzani and Philippe Martinet</div>
                <button v-if="!findMore2" class="btn-inv" v-on:click="findMore2=true">[more]</button>
                <Paper v-if="findMore2" :abstract="icraAbstract" :keywords="icraKeywords" :linkpdf="`${publicPath}ICRA2020_Kabtoul_Spalanzani_Martinet.pdf`" :linkvideo="icraVideo"/>
                <button v-if="findMore2" class="btn-inv" v-on:click="findMore2=false">[less]</button>
            </div>
        </div>

    </div>
</section>
</template>

<script>
import Paper from './publication.vue'
export default {
  name: 'Publications',
  components:{
    Paper
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    findMore0: false,
    findMore1: false,
    findMore2: false,
    findMore3: false,
    icra22Abstract: "Abstract— Navigation in close proximity with pedestrians is a challenge on the way to fully automated vehicles. Pedestrian-friendly navigation requires an understanding of pedestrian reaction and intention. Merely safety based reactive systems can lead to sub-optimal navigation solutions resulting in the freezing of the vehicle in many scenarios. Moreover, a strictly reactive method can produce unnatural driving patterns which cannot guarantee the legibility or social acceptance of the automated vehicle. This work presents a proactive maneuvering method adapted to navigation in close interaction with pedestrians using a dynamic channel approach. The method allows to proactively explore the navigation options based on anticipating pedestrians cooperation. The navigation is tested in frontal and lateral crossing scenarios with variable space density. The system is implemented under ROS, and compared with the probabilistic Risk-RRT planning method. The results are evaluated based on the safety and comfort of the pedestrians, and the quality of the vehicle's trajectory.",
    icra22Keywords: "Proactive Dynamic Channel, Social Navigation, Pedestrins-Vehicle Interaction, Shared Spaces, Lateral Control", 
    itscAbstract: "Abstract— Navigation in pedestrian populated environments is a highly challenging task, and a milestone on the way to fully autonomous urban driving systems. Pedestrian populated environments are highly dynamic, uncertain and difficult to predict. The strict safety measures in such environments result in overly reactive navigation systems, which do not match the conduct of experienced drivers. An autonomous vehicle driving alongside pedestrians should convey a natural and a socially-aware behaviour. Therefore, the vehicle should not merely react to the behaviour of the surrounding agents, but should rather cooperate and proactively interact with its surrounding. Excluding this aspect from the navigation scheme results in over-reactive behaviours, an unnatural driving pattern and a suboptimal navigation solution. This paper presents a proactive longitudinal velocity control method, appropriate for navigation in close interaction with pedestrians. The work uses a cooperation-based pedestrians-vehicle behavioural model to find the optimal longitudinal velocity control. The method is implemented in lateral crossing scenarios with a dense crowd of pedestrians. The results are then compared with a reactive navigation system. The method is evaluated in terms of the vehicle’s travel time and the safety of the pedestrians in the scene.",
    itscKeywords: "Proactive Navigation, Social Navigation, Pedestrins-Vehicle Interaction, Shared Spaces, Longitudinal Velocity Control, Probability Of Collision",
    itscVideo: "ITSC_final",
    icraAbstract: "Abstract— Developing autonomous vehicles capable of navigating safely and socially around pedestrians is a major challenge in intelligent transportation. This challenge cannot be met without understanding pedestrians' behavioral response to an autonomous vehicle, and the task of building a clear and quantitative description of the pedestrian to vehicle interaction remains a key milestone in autonomous navigation research. As a step towards safe proactive navigation in a space shared with pedestrians, this work introduces a pedestrian-vehicle interaction behavioral model. The model estimates the pedestrian's cooperation with the vehicle in an interaction scenario by a quantitative time-varying function. Using this cooperation estimation the pedestrian's trajectory is predicted by a cooperation-based trajectory planning model. Both parts of the model are tested and validated using real-life recorded scenarios of pedestrian-vehicle interaction. The model is capable of describing and predicting agents' behaviors when interacting with a vehicle in both lateral and frontal crossing scenarios.",
    icraKeywords: "Agents Modeling, Agents Simulation, Cooperative Navigation, Pedestrian Behaviors, Learning, Verification",
    icraVideo: "icra_final_LQ",
    titsAbstract: "Abstract—The navigation of autonomous vehicles around pedestrians is a key challenge when driving in urban environments. It is essential to test the proposed navigation system using simulation before moving to real-life implementation and testing. Evaluating the performance of the system requires the design of a diverse set of tests which spans the targeted working scenarios and conditions. These tests can then undergo a process of evaluation using a set of adapted performance metrics. This work addresses the problem of performance evaluation for an autonomous vehicle in a shared space with pedestrians. The methodology for designing the test simulations is discussed. Moreover, a group of performance metrics is proposed to evaluate the different aspects of the navigation: the motion safety, the quality of the generated trajectory and the comfort of the pedestrians surrounding the vehicle. Furthermore, the success/fail criterion for each metric is discussed. The implementation of the proposed evaluation method is illustrated by evaluating the performance of a pre-designed proactive navigation system using a shared space crowd simulator under ROS.",
    titsKeywords: "Autonomous Vehicles, Social Navigation, Evaluation Methods, Agents Simulation" })

}
</script>


